<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      @click="abrirModalQR"
    >
      {{ tituloBoton }}
    </b-button>
    <b-modal
      v-model="verModalLuminaria"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :size="modalSize"
      hide-footer
      modal-class="modal-primary"
      centered
      :title="tituloModal"
      @hide="ocultarModal"
    >
      <b-overlay
        :show="loading"
      >
        <div>
          <b-row>
            <b-col
              cols="5"
            >
              <center>
                <span
                  class="text-primary"
                  style="font-weight: bold; margin-right: 8px; margin-top: 100px"
                >
                  Escaneo por cámara
                </span>
              </center>
            </b-col>
            <b-col cols="2">
              <center>
                <b-form-checkbox
                  v-model="tipoEscaner"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  @change="nuevoEscaneo"
                />
              </center>
            </b-col>
            <b-col
              cols="5"
            >
              <center>
                <span
                  class="text-primary"
                  style="font-weight: bold; margin-right: 8px; margin-top: 100px"
                >
                  Escaneo por lector
                </span>
              </center>
            </b-col>
            <b-col
              v-if="!tipoEscaner"
              style="display: flex; justify-content: center"
              cols="12"
            >
              <h2
                v-if="rows.length === 0"
                class="text-primary text-uppercase"
              >
                {{ tituloInstruccion }}
              </h2>
              <br v-else>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="!tipoEscaner"
        >
          <b-col
            v-if="!datosEscaneadosMobile"
            cols="12"
          >
            <escaner-mobile
              :origen="'mantenimientoBodega'"
              @datos="escaneoMobileLuminaria"
            />
          </b-col>
        </b-row>
        <b-row
          v-else
          style="display: flex; justify-content: center"
        >
          <b-row>
            <b-col
              v-if="!textoEscaneado"
              style="display: flex; justify-content: center"
              cols="12"
            >
              <h2
                v-if="rows.length === 0"
                class="text-primary text-uppercase"
              >
                {{ tituloInstruccion }}
              </h2>
              <br v-else>
            </b-col>
            <b-col
              v-if="!textoEscaneado"
              style="display: flex; justify-content: center"
              cols="12"
            >
              <b-img
                :src="require('/public/qr-code-icon.gif')"
                alt="Escaner QR"
                style="width: 180px; height: 180px; border-radius: 15px; padding: 5px; box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);"
              />
            </b-col>
          </b-row>
          <b-col cols="12">
            <input
              ref="inputQR"
              v-model="textoEscaneado"
              class="qr-textarea"
              type="text"
              style="position: absolute; top: -9999px"
              @change="extraerCodigos"
            >
          </b-col>
        </b-row>
        <b-row v-if="origen === 'mantenimientoBodega' && (textoEscaneado !== null || datosEscaneadosMobile !== null)">
          <b-col
            v-if="textoEscaneado !== null || datosEscaneadosMobile !== null"
            cols="6"
          >
            <b-button
              block
              variant="primary"
              @click="nuevoEscaneo"
            >
              <feather-icon
                icon="CameraIcon"
                class="mr-50"
              />
              Escanear un nuevo código QR
            </b-button>
          </b-col>
          <b-col
            v-if="textoEscaneado !== null || datosEscaneadosMobile !== null"
            cols="6"
          >
            <b-button
              block
              variant="primary"
              @click="procesarEscaneo"
            >
              <span class="align-middle">{{ tituloProcesar }}</span>
              <feather-icon
                icon="SaveIcon"
                class="ml-50"
              />
            </b-button>
          </b-col>
          <b-col
            v-if="textoEscaneado !== null || datosEscaneadosMobile !== null"
            cols="12"
          >
            <br>
            <h6 class="text-primary">
              SERIE DE LUMINARIA:
            </h6>
            <b-form-group>
              <b-form-input
                :value="tipoEscaner ? decodedBase64Object && decodedBase64Object.codigo : datosEscaneadosMobile && datosEscaneadosMobile.codigo"
                disabled
                class="text-center"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="textoEscaneado !== null || datosEscaneadosMobile !== null"
            cols="12"
          >
            <vue-good-table
              :columns="columns"
              :rows="rows"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar',
              }"
              @on-row-dblclick="showDetalle"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field == 'estado'">
                  <b-badge :variant="getColorVariante(props.row.estado)">
                    {{ props.row.estado }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field == 'fechaCreacion'">
                  <span>{{ calcularFecha(props.row.fechaCreacion) }}</span>
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Mostrar
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="value => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> de {{ props.total }} registros </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="value => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <div>
      <b-modal
        id="modal-bodega"
        v-model="detalleBodega"
        title="Detalle de Recepción de Luminaria"
        size="lg"
        ok-only
        ok-title="Aceptar"
        no-close-on-backdrop
      >
        <detalle-bodega
          :luminaria="luminaria"
          @cerrar-detalle="cerrarDetalle"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import EscanerMobile from '@/components/escanersQr/escanerMobile.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { findLuminarias, variantesEstado } from '@/utils/mantenimiento/bodega/bodegaUtils'
import { calcularFecha } from '@/utils/fechas'
import DetalleBodega from '@/components/mantenimiento/bodega/detalle-bodega.vue'
import * as Base64 from 'js-base64'
import { mensajeError } from '@/utils/mensajes'

export default {
  components: {
    DetalleBodega,
    BCol,
    BRow,
    BImg,
    BBadge,
    BButton,
    BOverlay,
    BFormSelect,
    BPagination,
    BFormInput,
    BFormGroup,
    EscanerMobile,
    BFormCheckbox,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    origen: {
      type: String,
      required: true,
    },
    tituloModal: {
      type: String,
      required: false,
      default: 'Escaner QR',
    },
    tituloBoton: {
      type: String,
      required: false,
      default: 'Escaner QR',
    },
    tituloInstruccion: {
      type: String,
      required: false,
      default: 'Escanea el Código QR',
    },
    tituloProcesar: {
      type: String,
      required: false,
      default: () => 'Ingresar Luminaria',
    },
    modalSize: {
      type: String,
      requiere: false,
      default: 'md',
    },
  },
  data() {
    return {
      calcularFecha,
      loading: false,
      detalleBodega: false,
      tipoEscaner: false,
      verModalLuminaria: false,
      textoEscaneado: null,
      datosEscaneadosMobile: null,
      decodedBase64: null,
      decodedBase64Object: null,
      luminaria: null,
      columns: [
        {
          label: 'No. Poste',
          field: 'numeroPoste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie Lámpara',
          field: 'codigo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
      pageLength: 15,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    datosEscaneadosMobile: {
      handler(newValue) {
        if (newValue) {
          this.accionarBusqueda()
        }
      },
      deep: true,
    },
    decodedBase64Object: {
      handler(newValue) {
        if (newValue) {
          this.accionarBusqueda()
        }
      },
      deep: true,
    },
    tipoEscaner: {
      handler(nuevoValor) {
        if (nuevoValor) {
          this.iniciarIntervalo()
        } else {
          this.detenerIntervalo()
        }
      },
      immediate: true,
    },
  },
  methods: {
    accionarBusqueda() {
      if (this.datosEscaneadosMobile) {
        if (this.tipoEscaner === false) {
          this.loadReport()
        }
      } if (this.decodedBase64Object) {
        if (this.tipoEscaner === true) {
          this.loadReport()
        }
      }
    },
    procesarEscaneo() {
      try {
        if (this.origen === 'mantenimientoBodega') {
          if (this.datosEscaneadosMobile) {
            this.newWindow = window.open('', '_blank')
            this.newWindow.location.href = `/recepcion-bodega?action=Create&scannedData=${encodeURIComponent(JSON.stringify(this.datosEscaneadosMobile))}`
          } else if (this.textoEscaneado) {
            this.newWindow = window.open('', '_blank')
            this.newWindow.location.href = `/recepcion-bodega?action=Create&scannedData=${encodeURIComponent(JSON.stringify(this.decodedBase64Object))}`
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    extraerCodigos() {
      this.loading = true
      try {
        if (this.origen === 'mantenimientoBodega') {
          this.decodedBase64 = Base64.decode(this.textoEscaneado)
          this.decodedBase64Object = JSON.parse(this.decodedBase64)
        }
      } catch (error) {
        mensajeError('Código QR no válido, intenta nuevamente!')
        this.nuevoEscaneo()
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async loadReport() {
      this.loading = true
      try {
        if (this.origen === 'mantenimientoBodega') {
          const filter = {
            where: {
              and: [
                { companyId: this.usuario.idCompany },
              ],
            },
            order: ['fechaCreacion DESC'],
          }

          if (!this.tipoEscaner) {
            if (this.datosEscaneadosMobile && this.datosEscaneadosMobile.codigo !== '') {
              filter.where.and.push({ codigo: this.datosEscaneadosMobile.codigo })
            }
          } else if (this.decodedBase64Object && this.decodedBase64Object.codigo !== '') {
            filter.where.and.push({ codigo: this.decodedBase64Object.codigo })
          }

          // eslint-disable-next-line no-restricted-syntax
          this.rows = await findLuminarias(filter)
        }
      } catch (error) {
        console.error('Error loading report:', error)
      } finally {
        this.loading = false
        this.detenerIntervalo()
      }
    },
    async abrirModalQR() {
      try {
        this.verModalLuminaria = true
      } catch (error) {
        console.error(error)
      } finally {
        clearInterval(this.timeoutId)
      }
    },
    escaneoMobileLuminaria(datosEscaneados) {
      this.datosEscaneadosMobile = datosEscaneados
    },
    iniciarIntervalo() {
      this.timeoutId = setInterval(() => {
        this.$nextTick(() => {
          if (this.$refs.inputQR) {
            console.log('ACTIVO')
            this.$refs.inputQR.focus()
          }
        })
      }, 500)
    },
    detenerIntervalo() {
      clearInterval(this.timeoutId)
    },
    ocultarModal() {
      this.tipoEscaner = false
      this.nuevoEscaneo()
      clearInterval(this.timeoutId)
    },
    async showDetalle(params) {
      const luminaria = params.row
      if (luminaria) {
        this.luminaria = luminaria
        this.detalleBodega = true
      }
    },
    nuevoEscaneo() {
      this.datosEscaneadosMobile = null
      if (this.origen === 'mantenimientoBodega') {
        this.decodedBase64Object = null
        this.textoEscaneado = null
        this.rows = []
      }
    },
    getColorVariante(estado) {
      return variantesEstado[estado] || 'light-secondary'
    },
    cerrarDetalle() {
      try {
        this.detalleBodega = false
      } catch (error) {
        console.log(error)
      } finally {
        clearInterval(this.timeoutId)
      }
    },
  },
}
</script>
<style scoped>

</style>
